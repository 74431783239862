@use '../abstracts' as a;

.konami-container {
    @include a.flex(column, space-around);
    position: fixed;
    width: 100%;
    height: 70%;
    z-index: 10;
    bottom: 100%;
}

.konami-row {
    @include a.flex(row, space-around);
    width: 100%;
}

.controller-emoji {
    font-size: 3rem;
    margin: 0rem;
    width: auto;
    text-align: center;
}

@media (width >= 44rem) {
    .controller-emoji {
        font-size: 4rem;
    }
}