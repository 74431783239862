@mixin flex($direction: column, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin arrowDirection($degrees) {
  position: relative;
  
  svg {
    transform: rotate(#{$degrees}deg);
  }
}
