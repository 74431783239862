@use '../abstracts' as a;

body {
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

section {
  flex-grow: 1;
  height: 100%;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

.page-title {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.flex-grid {
  @include a.flex(row);
  flex-wrap: wrap;
  margin-top: 2rem;
}

.content,
main {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding-top: a.$navHeight;
  z-index: 0;
}

.content-holder {
  max-width: min(100%, 70rem);
  margin: auto;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-5 {
  flex-grow: 5;
}

@media (min-width: 44rem) {
  .page-title {
    margin-left: 2rem;
    justify-content: start;
  }
}
