* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  color: unset;
  text-decoration: none;
}

img, svg {
  display: block;
}

body {
  height: 100vh;
  overflow-x: hidden;
}

nav button {
  background: none;
  border: none;
  width: auto;
  height: auto;
  padding: 1px;
}

input, button {
  appearance: none;
}

h1 {
  color: #cdcdcd;
  font-family: Roboto;
  font-size: 3rem;
  font-weight: bold;
}

a, h3 {
  color: #cdcdcd;
  font-family: Roboto Mono;
  font-size: 1.4rem;
  font-weight: normal;
}

p, label, input, textarea, .modal-button {
  color: #cdcdcd;
  font-family: Roboto Mono;
  font-size: .8rem;
  font-weight: normal;
}

.inline-link {
  color: #67e34b;
  font-family: Roboto Mono;
  font-size: .8rem;
  font-weight: normal;
}

p {
  margin: 1rem 2rem;
}

label {
  margin-bottom: .25rem;
}

.page-title > h1, .accent-text {
  color: #67e34b;
}

.default-text-color {
  color: #cdcdcd;
}

.page-title > h1 {
  margin-bottom: -1rem;
}

.subtitle-text {
  font-size: 1.2rem;
}

.center-text {
  text-align: center;
}

@media (width >= 44rem) {
  h1 {
    font-size: 4rem;
  }

  a, h3 {
    font-size: 1.8rem;
  }

  .subtitle-text {
    font-size: 1.5rem;
  }

  p, label, input, textarea, .modal-button, .inline-link {
    font-size: 1rem;
  }

  p {
    margin: 2rem;
  }
}

.form-button {
  width: 5.5rem;
  height: 2rem;
}

.form-button:hover, .form-button:focus {
  color: #343434;
  -moz-focus-inner: none;
  background-color: #67e34b;
  outline: none;
  font-weight: bold;
}

.modal-button {
  color: #343434;
  background-color: #cdcdcd;
  border: none;
  border-radius: 4px;
  width: 4.5rem;
  height: 1.5rem;
  font-weight: bold;
}

.modal-button:hover, .modal-button:focus {
  color: #343434;
  -moz-focus-inner: none;
  background-color: #67e34b;
  outline: none;
  font-weight: bold;
}

@media (width >= 44rem) {
  .modal-button {
    width: 6rem;
    height: 2rem;
  }
}

.about-cards {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.about-card {
  background-color: #343434;
  border-radius: 4px;
  width: clamp(10rem, 80%, 18rem);
  height: auto;
  box-shadow: 4px 4px 5px #0003;
}

.about-card + .about-card {
  margin-top: 1rem;
}

.about-card h3 {
  text-align: center;
  margin-top: 1rem;
}

.about-card:last-child {
  margin-bottom: 1rem;
}

.about-card p {
  margin: 1rem;
}

.about-card p span {
  font-size: 1rem;
  font-weight: bold;
}

.project-card {
  background-color: #343434;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18rem;
  height: 10rem;
  margin: .5rem 1rem;
  display: flex;
  position: relative;
  box-shadow: 4px 4px 5px #0003;
}

.project-card:hover .hover-content p, .project-card:hover .hover-content h3 {
  color: #67e34b;
}

.project-image {
  object-fit: cover;
  filter: brightness(20%);
  text-shadow: 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.hover-content {
  text-align: center;
  text-shadow: 1px 1px #000, -1px -1px #000, -1px 1px #000, 1px -1px #000;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
}

.hover-content p {
  margin-top: .5rem;
}

@media (width <= 19.99rem) {
  .project-card {
    height: 16rem;
  }
}

@media (width >= 44rem) {
  .project-card {
    width: clamp(17.5rem, 35%, 21rem);
    height: 18rem;
  }

  .about-card {
    width: clamp(18rem, 45%, 21rem);
    height: 22rem;
  }

  .about-cards {
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    display: flex;
  }

  .about-card {
    margin-bottom: 1rem;
  }

  .about-card + .about-card {
    margin-top: 0;
    margin-left: 2rem;
  }
}

.arrow-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: .8rem;
  display: flex;
  position: absolute;
  top: 3.5rem;
}

.back-arrow {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.back-arrow:hover p {
  color: #67e34b;
}

.back-arrow p {
  margin: 0;
}

.left-arrow {
  position: relative;
}

.left-arrow svg {
  transform: rotate(90deg);
}

.right-arrow {
  position: relative;
}

.right-arrow svg {
  transform: rotate(-90deg);
}

.up-arrow {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1rem;
  display: flex;
  position: absolute;
  top: 3.5rem;
  left: 0;
}

.up-arrow .back-arrow {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.up-arrow .back-arrow svg {
  transform: rotate(180deg);
}

.down-arrow {
  width: 100%;
  position: absolute;
  left: 0;
}

.down-arrow h3 {
  margin-bottom: .5rem;
}

.small-arrow {
  width: 1.5rem;
  height: .5rem;
}

.large-arrow {
  width: 2.6rem;
  height: 1rem;
}

.large-arrow--longer {
  width: 3.25rem;
  height: 1rem;
}

@media (width >= 81rem) {
  .arrow-container {
    margin-top: 0;
    position: fixed;
    top: 50%;
  }
}

.modal {
  opacity: 0;
  contain: paint;
  text-align: center;
  z-index: 6;
  pointer-events: none;
  background-color: #343434;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: clamp(10rem, 60%, 20rem);
  height: 6rem;
  display: flex;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}

@media (width >= 44rem) {
  .modal {
    height: 10rem;
  }
}

.konami-container {
  z-index: 10;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 70%;
  display: flex;
  position: fixed;
  bottom: 100%;
}

.konami-row {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  display: flex;
}

.controller-emoji {
  text-align: center;
  width: auto;
  margin: 0;
  font-size: 3rem;
}

@media (width >= 44rem) {
  .controller-emoji {
    font-size: 4rem;
  }
}

body {
  flex-direction: column;
  display: flex;
}

main {
  flex-grow: 1;
}

section {
  flex-grow: 1;
  height: 100%;
}

.flex-row {
  display: flex;
}

.flex-column {
  flex-direction: column;
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

.page-title {
  justify-content: center;
  margin-top: 3rem;
  display: flex;
}

.flex-grid {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  display: flex;
}

.content, main {
  z-index: 0;
  width: 100%;
  height: 100%;
  padding-top: 3rem;
  position: absolute;
  top: 0;
}

.content-holder {
  max-width: min(100%, 70rem);
  margin: auto;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-5 {
  flex-grow: 5;
}

@media (width >= 44rem) {
  .page-title {
    justify-content: start;
    margin-left: 2rem;
  }
}

form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

form .form-button {
  margin-top: 1rem;
}

input, textarea {
  background-color: #343434;
  border: none;
  border-radius: 4px;
  width: 100%;
}

input:active, input:focus, textarea:active, textarea:focus {
  caret-color: #67e34b;
  outline: none;
  box-shadow: 0 0 0 1px #67e34b;
}

textarea {
  resize: none;
  height: 6rem;
  padding: .25rem .5rem;
}

input {
  height: 1.8rem;
  padding: 0 .5rem;
}

.form-element {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: clamp(10rem, 80%, 40rem);
  display: flex;
}

.form-element + .form-element {
  margin-top: .5rem;
}

.form-top-row {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.form-top-row + .form-element {
  margin-top: .5rem;
}

@media (width >= 44rem) {
  .form-top-row {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: clamp(10rem, 80%, 40rem);
    display: flex;
  }

  .form-top-row .form-element + .form-element {
    margin-top: 0;
    margin-left: 1rem;
  }

  input {
    height: 2rem;
  }

  textarea {
    height: 10rem;
  }
}

nav {
  z-index: 2;
  background-color: #000;
  width: 100%;
  height: 3rem;
  position: absolute;
}

.hamburger {
  z-index: 3;
  pointer-events: all;
  width: 24px;
}

.github {
  width: 1.5rem;
  height: auto;
}

.linked-in {
  width: 1.375rem;
  height: auto;
}

.itchio {
  width: 1.5rem;
  height: auto;
}

.nav-logo {
  width: 36px;
}

.nav-logo-container {
  padding: 0 12px;
}

.right-nav {
  z-index: 6;
}

.right-nav button {
  margin: 0 12px;
}

.social-links a {
  margin: 0 6px;
}

.hamburger-content {
  z-index: 5;
  contain: paint;
  opacity: 0;
  pointer-events: none;
  background-color: #343434;
  border: 1px solid #cdcdcd;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 10.1rem;
  height: 10rem;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.hamburger-content ul {
  width: 100%;
  list-style-type: none;
}

.hamburger-content li {
  text-align: center;
  border-top: 1px solid #cdcdcd;
  padding: .225rem 0;
}

.hamburger-content li:hover a {
  color: #67e34b;
}

@media (width >= 44rem) {
  .hamburger-content {
    width: 12rem;
    height: 11.75rem;
  }
}

.dim-overlay {
  z-index: 4;
  opacity: 0;
  pointer-events: none;
  backdrop-filter: blur(.25rem);
  background-color: #0009;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.name {
  position: relative;
  bottom: 4rem;
}

.page-links:hover h3 {
  color: #67e34b;
}

.links {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  display: flex;
}

@media (width <= 35rem) {
  .links {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

@media (width >= 44rem) {
  .links {
    width: min(100%, 100rem);
    position: relative;
  }

  .projects-link {
    position: relative;
    top: .68rem;
  }

  .home-arrow, .name {
    position: static;
  }
}

.project-holder {
  width: min(100%, 70rem);
  margin: auto;
}

body {
  background-image: url("background.0db3c46d.png");
  background-size: cover;
  background-attachment: fixed;
}

.svg-color-hover {
  fill: #cdcdcd;
}

.svg-hover:hover .svg-color-hover {
  fill: #67e34b;
}
/*# sourceMappingURL=index.b6a34add.css.map */
