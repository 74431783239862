@use '../abstracts' as a;

form {
  @include a.flex();

  .form-button {
    margin-top: 1rem;
  }
}

input,
textarea {
  background-color: a.$areaGrey;
  border: none;
  border-radius: 4px;
  width: 100%;

  &:active,
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px a.$green;
    caret-color: a.$green;
  }
}

textarea {
  resize: none;
  height: 6rem;
  padding: 0.25rem 0.5rem;
}

input {
  height: 1.8rem;
  padding: 0 0.5rem;
}

.form-element {
  @include a.flex($align: flex-start);
  width: clamp(10rem, 80%, 40rem);

  & + .form-element {
    margin-top: 0.5rem;
  }
}

.form-top-row {
  @include a.flex();
  width: 100%;

  & + .form-element {
    margin-top: 0.5rem;
  }
}

@media (min-width: 44rem) {
  .form-top-row {
    width: clamp(10rem, 80%, 40rem);
    @include a.flex(row);

    .form-element + .form-element {
      margin-left: 1rem;
      margin-top: 0;
    }
  }

  input {
    height: 2rem;
  }

  textarea {
    height: 10rem;
  }
}
