@use '../abstracts' as a;

h1 {
  font-family: Roboto;
  font-weight: bold;
  color: a.$textGrey;
  font-size: 3rem;
}

a,
h3 {
  font-family: Roboto Mono;
  font-weight: normal;
  color: a.$textGrey;
  font-size: 1.4rem;
}

p,
label,
input,
textarea,
.modal-button {
  font-family: Roboto Mono;
  font-weight: normal;
  color: a.$textGrey;
  font-size: 0.8rem;
}

.inline-link {
  font-family: Roboto Mono;
  font-weight: normal;
  color: a.$green;
  font-size: 0.8rem;
}

p {
  margin: 1rem 2rem;
}

label {
  margin-bottom: 0.25rem;
}

.page-title > h1,
.accent-text {
  color: a.$green;
}

.default-text-color {
  color: a.$textGrey;
}

.page-title > h1 {
  margin-bottom: -1rem;
}

.subtitle-text {
  font-size: 1.2rem;
}

.center-text {
  text-align: center;
}

@media (min-width: 44rem) {
  h1 {
    font-size: 4rem;
  }

  a,
  h3 {
    font-size: 1.8rem;
  }

  .subtitle-text {
    font-size: 1.5rem;
  }

  p,
  label,
  input,
  textarea,
  .modal-button, .inline-link {
    font-size: 1rem;
  }

  p {
    margin: 2rem 2rem;
  }
}
