@use '../abstracts' as a;

.form-button {
  height: 2rem;
  width: 5.5rem;

  &:hover,
  &:focus {
    background-color: a.$green;
    color: a.$areaGrey;
    font-weight: bold;
    outline: none;
    -moz-focus-inner: none;
  }
}

.modal-button {
  height: 1.5rem;
  width: 4.5rem;

  background-color: a.$textGrey;
  color: a.$areaGrey;
  border: none;
  font-weight: bold;
  border-radius: 4px;

  &:hover,
  &:focus {
    background-color: a.$green;
    color: a.$areaGrey;
    font-weight: bold;
    outline: none;
    -moz-focus-inner: none;
  }
}

@media (min-width: 44rem) {
  .modal-button {
    height: 2rem;
    width: 6rem;
  }
}
