.dim-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(black, 0.6);
  top: 0;
  left: 0;
  z-index: 4;
  opacity: 0;
  pointer-events: none;
  backdrop-filter: blur(0.25rem);
}
