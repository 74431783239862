@use '../abstracts' as a;

body {
  background-image: url("../public/background.png");
  background-attachment: fixed;
  background-size: cover;
}

.svg-color-hover {
  fill: a.$textGrey
}

.svg-hover:hover  {
  .svg-color-hover {
    fill: a.$green;
  }
  
}
