@use '../abstracts' as a;

.arrow-container {
  @include a.flex(row, space-between, center);
  position: absolute;
  top: 3.5rem;
  width: 100%;
  margin-top: 0.8rem;
}

.back-arrow {
  @include a.flex(row, center, center);

  &:hover p {
    color: a.$green;
  }

  p {
    margin: 0;
  }
}

.left-arrow {
  @include a.arrowDirection(90);
}

.right-arrow {
  @include a.arrowDirection(-90);
}

.up-arrow {
  position: absolute;
  @include a.flex();
  width: 100%;
  height: 1rem;
  left: 0;
  top: 3.5rem;

  .back-arrow {
    @include a.arrowDirection(180);
    @include a.flex();
  }
}

.down-arrow {
  position: absolute;
  width: 100%;
  left: 0;

  h3 {
    margin-bottom: 0.5rem;
  }
}

.small-arrow {
  width: 1.5rem;
  height: 0.5rem;
}

.large-arrow {
  width: 2.6rem;
  height: 1rem;
}

.large-arrow--longer {
  height: 1rem;
  width: 3.25rem;
}

@media (min-width: 81rem) {
  .arrow-container {
    top: 50%;
    position: fixed;
    margin-top: 0;
  }
}
