@use '../abstracts' as a;

nav {
  background-color: #000000;
  height: a.$navHeight;
  position: absolute;
  z-index: 2;
  width: 100%;
}

.hamburger {
  width: 24px;
  z-index: 3;
  pointer-events: all;
}

.github {
  width: 1.5rem;
  height: auto;
}

.linked-in {
  width: 1.375rem;
  height: auto;
}

.itchio {
  width: 1.5rem;
  height: auto;
}

.nav-logo {
  width: 36px;
}

.nav-logo-container {
  padding: 0 12px;
}

.right-nav {
  z-index: 6;
  button {
    margin: 0 12px;
  }
}

.social-links {
  a {
    margin: 0 6px;
  }
}

.hamburger-content {
  z-index: 5;
  position: absolute;
  contain: paint;
  background-color: a.$areaGrey;
  width: 10.1rem;
  height: 10rem;
  top: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  border: 1px solid a.$textGrey;
  @include a.flex($justify: flex-end);

  ul {
    width: 100%;
    list-style-type: none;
  }

  li {
    text-align: center;
    border-top: 1px solid a.$textGrey;
    padding: 0.225rem 0;

    &:hover a {
      color: a.$green;
    }
  }
}

@media (min-width: 44rem) {
  .hamburger-content {
    width: 12rem;
    height: 11.75rem;
  }
}
