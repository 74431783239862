@use '../abstracts' as a;

.about-cards {
  @include a.flex();
}

.about-card {
  background-color: a.$areaGrey;
  width: clamp(10rem, 80%, 18rem);
  height: auto;
  border-radius: 4px;
  box-shadow: 4px 4px 5px rgba(black, 0.2);
  

  & + .about-card {
    margin-top: 1rem;
  }

  & h3 {
    text-align: center;
    margin-top: 1rem;
  }

  &:last-child {
    margin-bottom: 1rem;
  }

  p {
    margin: 1rem 1rem;

    span {
      font-weight: bold;
      font-size: 1rem;
    }
  }
}

.project-card {
  @include a.flex();
  position: relative;
  height: 10rem;
  width: 18rem;
  background-color: a.$areaGrey;
  margin: 0.5rem 1rem;
  border-radius: 4px;
  box-shadow: 4px 4px 5px rgba(black, 0.2);

  &:hover {
    .hover-content {
      p,
      h3 {
        color: a.$green;
      }
    }
  }
}

.project-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(20%);
  border-radius: 4px;
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000;
  top: 0;
}

.hover-content {
  @include a.flex($justify: space-around);
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000;

  top: 0;

  p {
    margin-top: 0.5rem;
  }
}

@media (max-width: 19.99rem) {
  .project-card {
    height: 16rem;
  }
}

@media (min-width: 44rem) {
  .project-card {
    height: 18rem;
    width: clamp(17.5rem, 35%, 21rem);
  }

  .about-card {
    width: clamp(18rem, 45%, 21rem);
    height: 22rem;
  }

  .about-cards {
    @include a.flex(row);
    flex-wrap: wrap;
    margin: 0 1rem;
  }

  .about-card {
    margin-bottom: 1rem;
  }

  .about-card + .about-card {
    margin-top: 0;
    margin-left: 2rem;
  }
}
