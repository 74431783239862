* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: unset;
}

img {
  display: block;
}

svg {
  display: block;
}

body {
  height: 100vh;
  overflow-x: hidden;
}

nav button {
  background: none;
  border: none;
  width: auto;
  height: auto;
  padding: 1px;
}

input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
