@use '../abstracts' as a;

.name {
  position: relative;
  bottom: 4rem;
}

.page-links {
  &:hover h3 {
    color: a.$green;
  }
}

.links {
  @include a.flex(row, space-around);
  width: 100%;
}

@media (max-width: 35rem) {
  .links {
    @include a.flex(row, space-between);
  }
}

@media (min-width: 44rem) {
  .links {
    position: relative;
    width: min(100%, 100rem);
  }

  .projects-link {
    position: relative;
    top: 0.68rem;
  }

  .home-arrow {
    position: static;
  }

  .name {
    position: static;
  }
}
